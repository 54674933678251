import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import { format, parse } from 'date-fns';

import { CalendarIcon } from '@mc/wink-icons';
import { Calendar as CalendarIDS } from '@design-systems/icons';
import useId from '@mc/hooks/useId';
import { SUPPORTED_LOCALES_DATE } from '@mc/internationalization/constants';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import { Calendar, IconButton, Input } from '@mc/wink';
import Popup from '../Popup';
// TODO:
// Make this into a wink-specific hook?
// Inline it into Popup and flag it behind a prop?
import hideAppLevelElements from '../Dialog/ariaHider';
import { TranslateInput } from '../Input/TranslateInput';
import { TranslatedDays } from '../Calendar/TranslateCalendar';
import stylesheet from './InputDate.css';

const NOW = new Date();

let unhideAppLevelElements = () => {};

export type DateFilter = (e: Date) => boolean;

export type CalendarPopupProps = {
  dateFilter?: DateFilter;
  max?: Date | number;
  min?: Date | number;
  onChange: (e: Date) => void;
  onRequestClose: () => void;
  startDayOfWeek?:
    | 'Sunday'
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | undefined;
  targetRef: React.MutableRefObject<HTMLElement | null>;
  value: Date;
  format?: string;
};

export type InputDateProps = {
  /** Optional function to arbitrarily disable dates for selection. */
  dateFilter?: DateFilter;
  /** Whether or not the form field is interactive. */
  disabled?: boolean;
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error?: string;
  /** The format in which the date will be rendered into the text field. Proceed with caution! Locale formatting is built in.*/
  format?: string;
  /** Text that appears below the input. */
  helpText?: React.ReactNode;
  /** Visually hides the label provided by the `label` prop. */
  hideLabel?: boolean;
  /** The label of the input. */
  label?: React.ReactNode;
  /** An optional, upper bound for selectable dates. */
  max?: Date | number;
  /** An optional, lower bound for selectable dates. */
  min?: Date | number;
  /** Helpful text that expounds upon the field's usage. */
  miscText?: React.ReactNode;
  /** Fires when input field is no longer focused  */
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  /** The callback to fire when the user selects a new date. */
  onChange: (e: Date | null) => void;
  /** The callback to fire when the user opens the calendar */
  onOpenCalendar?: () => void;
  /** Whether or not the form field is read-only. */
  readOnly?: boolean;
  /** Controls what day of week the calendar popup starts on. */
  startDayOfWeek?:
    | 'Sunday'
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday';
  /** The value for the field. */
  value?: Date;
};

export function CalendarPopup({
  targetRef,
  onRequestClose,
  onChange,
  ...props
}: CalendarPopupProps) {
  const ref = useRef<HTMLDivElement>(null);

  const describedbyId = useId();
  const { inputDateMsg } = TranslateInput();

  // Handle focus management on open/close
  // This was lifted from @mc/wink/components/Dialog
  useEffect(() => {
    const previousFocus = document.activeElement;
    const root = ref.current;
    root!.focus();
    // Hide elements
    const popoverEls = [
      ...document.querySelectorAll('.mcds-popup-portal-root'),
    ];
    // ProseMirror uses `MutationObserver` on its content to detect
    // changes to the html element it manages. The `inert` polyfill causes attribute
    // changes to occur on elements inside the ProseMirror container.
    // ProseMirror has a schema that defines what are valid element and attributes so it
    // attempts fix them. When there's more than 1 child element on the ProseMirror container
    // that the `inert` polyfill mutates, it causes an infinite loop between these two
    // `MutationObserver`. For now, we will ignore the content of ProseMirror.
    // This can be deleted if the `inert` polyfill is no longer used.
    const proseMirrorContainers = [
      ...document.querySelectorAll('.ProseMirror'),
    ];
    const ignoreElements = [root, ...popoverEls, ...proseMirrorContainers];
    // If a CalendarPopover event happens back to back, ensure that the return
    // has a chance to fire before hideAppLevelElements
    const timeout = setTimeout(() => {
      unhideAppLevelElements = hideAppLevelElements(ignoreElements);
    }, 100);
    return () => {
      unhideAppLevelElements();
      // The `inert` polyfill uses a `MutationObserver` to detect changes to
      // the `inert` attribute. We must let the current task end before
      // focusing the previous element. Check if we use the inert polyfill
      // (Element.prototype.inert on polyfill.mailchimp.com) before removing.
      setTimeout(() => {
        (previousFocus as HTMLElement).focus();
      }, 0);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const root = ref.current;

    function handleOutsideClick(e: MouseEvent) {
      if (e.target instanceof HTMLElement && !root!.contains(e.target)) {
        onRequestClose();
      }
    }

    document.addEventListener('click', handleOutsideClick, { capture: true });

    return function cleanup() {
      document.removeEventListener('click', handleOutsideClick, {
        capture: true,
      });
    };
  }, [onRequestClose]);

  return (
    <Popup
      targetRef={targetRef}
      className={stylesheet.popup}
      placement="bottom-end"
      offset={4}
    >
      <Calendar
        {...props}
        // Date range is not supported with InputDate, so we match the
        // type of Calendar onChange without impacting the end usage of InputDate
        onChange={(e: Date | [Date, Date | null]) => {
          if (e instanceof Date) {
            onChange(e);
          } else if (Array.isArray(e) && e[0] instanceof Date) {
            onChange(e[0]);
          }
        }}
        aria-describedby={describedbyId}
        ref={ref}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Escape' && !e.defaultPrevented) {
            onRequestClose();
          }
        }}
      />
      {/* Relevant to InputDate, not Calendar alone. */}
      <span className="wink-visually-hidden" id={describedbyId}>
        {inputDateMsg}
      </span>
    </Popup>
  );
}

const InputDate = React.forwardRef<HTMLInputElement, InputDateProps>(
  function InputDate(
    {
      // Default to an "always" filter
      dateFilter = () => true,
      error,
      format: inputFormat,
      max,
      min,
      onChange,
      onOpenCalendar,
      startDayOfWeek,
      value,
      ...props
    },
    forwardedRef,
  ) {
    // Set current locale based on browser locale. If browser locale is not supported,
    // 'en-US' format will be used or the format provided at implementation. MCDS components will rely on browser locale to decouple logic
    // from the MC Internationalization package because it is also used outside of in-app experiences.
    inputFormat = inputFormat || 'MM-dd-yyyy';
    SUPPORTED_LOCALES_DATE.forEach((locale) => {
      if (Object.values(locale).indexOf(navigator.language) > -1) {
        inputFormat = locale.format;
      }
    });

    const days = TranslatedDays();
    startDayOfWeek = startDayOfWeek || days[0];

    const iconButtonRef = useRef<HTMLInputElement | null>(null);
    // State for tracking user input
    const [inputValue, setInputValue] = useState(() => {
      return value ? format(value, inputFormat!) : '';
    });

    // State for user input validity
    const [hasInvalidInput, setHasInvalidInput] = useState(false);
    const [hasOutOfRangeInput, setHasOutOfRangeInput] = useState(false);

    // Popup state
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    // Translate default text
    const { inputDateOpenMsg, inputDateInvalidMsg, inputDateInvalidFormatMsg } =
      TranslateInput();

    const redesigned = mcdsFlagCheck('xp_mcds_redesign_components_molecules');

    const handleRef = (node: HTMLInputElement | null) => {
      iconButtonRef.current = node;
      if (forwardedRef) {
        if (typeof forwardedRef === 'function') {
          forwardedRef(node);
        } else {
          forwardedRef.current = node;
        }
      }
    };

    // Sync local state from upstream changes
    useEffect(() => {
      setInputValue(() => {
        return value ? format(value, inputFormat!) : '';
      });

      setHasInvalidInput(false);
      setHasOutOfRangeInput(false);
    }, [value, inputFormat]);

    return (
      <>
        <div
          className={stylesheet.inputWrapper}
          onClick={() => {
            if (!redesigned) {
              return;
            }
            if (onOpenCalendar) {
              onOpenCalendar();
            }
            setIsCalendarVisible((bool) => !bool);
          }}
        >
          <Input
            {...props}
            ref={redesigned ? handleRef : forwardedRef}
            type="text"
            value={inputValue}
            onChange={setInputValue}
            onFocus={() => {
              setHasInvalidInput(false);
              setHasOutOfRangeInput(false);
            }}
            // This keydown event is here to handle an edge case where a user
            // manually types in a date and hits Enter to submit a Form, avoiding
            // the onBlur which updates the value of the field.
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                const formattedValue = format(value || NOW, inputFormat!);
                // This condition is to handle two separate cases:
                // 1) The input is empty and has no value (undefined), the user enters a
                // new value (inputValue) and hits enter.
                // 2) The input had a valid default value at the start, the user enters
                // a new value and hits enter.

                // If any of these two conditions are true, check validation and if
                // it passes validation, we update the value.
                if (value === undefined || inputValue !== formattedValue) {
                  event.preventDefault();
                  const parsed = parse(inputValue, inputFormat!, value || NOW);
                  if (
                    !(parsed instanceof Date) ||
                    isNaN(parsed.getTime()) ||
                    // This is to ensure the parsed date has a full 4-digit year
                    // Without it, a year of 202 will be reformatted to 0202.

                    // This should still work even if the formatting passed to the
                    // 'format' prop of the this component excludes year such as
                    // 'MM/DD', because the 'parsed' date will always have a year
                    // associated with it.
                    parsed.getFullYear().toString().length !== 4
                  ) {
                    setHasInvalidInput(true);
                  } else if (
                    (min !== undefined && min > parsed) ||
                    (max !== undefined && max < parsed) ||
                    !dateFilter(parsed)
                  ) {
                    setHasOutOfRangeInput(true);
                  } else {
                    onChange(parsed);
                  }
                }
              }
            }}
            onBlur={(event) => {
              const parsed = inputValue
                ? parse(
                    inputValue,
                    inputFormat ? inputFormat : '',
                    value || NOW,
                  )
                : null;
              if (!parsed) {
                onChange(parsed);
              } else if (
                !(parsed instanceof Date) ||
                isNaN(parsed.getTime()) ||
                // This is to ensure the parsed date has a full 4-digit year
                // Without it, a year of 202 will be reformatted to 0202.

                // This should still work even if the formatting passed to the
                // 'format' prop of the this component excludes year such as
                // 'MM/DD', because the 'parsed' date will always have  a year
                // associated with it.
                parsed.getFullYear().toString().length !== 4
              ) {
                setHasInvalidInput(true);
              } else if (
                (min !== undefined && min > parsed) ||
                (max !== undefined && max < parsed) ||
                !dateFilter(parsed)
              ) {
                setHasOutOfRangeInput(true);
              } else {
                onChange(parsed);
              }
              if (props.onBlur) {
                props.onBlur(event);
              }
            }}
            /* Updating Calendar icon from an IconButton to just the icon itself per Q/A */
            suffixText={
              redesigned ? (
                <CalendarIDS width={20} height={20} />
              ) : (
                <IconButton
                  ref={iconButtonRef}
                  icon={<CalendarIcon />}
                  onClick={() => {
                    if (onOpenCalendar) {
                      onOpenCalendar();
                    }
                    setIsCalendarVisible((bool) => !bool);
                  }}
                  label={inputDateOpenMsg}
                  disabled={props.disabled || props.readOnly}
                />
              )
            }
            error={
              hasOutOfRangeInput
                ? inputDateInvalidMsg
                : hasInvalidInput
                ? `${inputDateInvalidFormatMsg} ${inputFormat.toLowerCase()}`
                : error
            }
          />
          {isCalendarVisible && (
            <CalendarPopup
              targetRef={iconButtonRef}
              min={min}
              max={max}
              dateFilter={dateFilter}
              format={inputFormat}
              value={value || NOW}
              /* Updated onChange to close the Calendar modal once a date is selected per Q/A */
              onChange={(selectedDate) => {
                onChange(selectedDate);
                setIsCalendarVisible(false);
              }}
              onRequestClose={() => {
                setIsCalendarVisible(false);
              }}
              startDayOfWeek={startDayOfWeek}
            />
          )}
        </div>
      </>
    );
  },
);

export default InputDate;
